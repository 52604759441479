import React from 'react'
import PropTypes from 'prop-types'

import Nav from './nav'
import Header from './header'
import Footer from './footer'
import '../css/global.css'
import '../css/shared.css'

const Layout = ({ children, home }) => (
  <div className="main-container">
    <Nav home={home} />
    <Header home={home} />
    <main className="container">{children}</main>
    <Footer />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  home: PropTypes.bool
}

Layout.defaultProps = {
  home: false
}

export default Layout
