import React from 'react'

import '../css/titleText.css'

const TitleText = () => {
  return (
    <h1 className="titleText">
      <span className="titleTextMain">
        <span className="titleTextMainWhite">Level</span>
        <span className="titleTextMainColor">Up</span>
      </span>
      <span className="titleTextSecondary">
        <span className="titleTextSecondaryText">in Tech</span>
      </span>
    </h1>
  )
}

export default TitleText
