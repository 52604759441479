import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import '../css/nav.css'

const MainNav = () => (
  <nav className="nav container">
    <ul className="nav-link-list">
      <li className="nav-item">
        <Link to="/">About</Link>
      </li>
      <li className="nav-item">
        <Link to="/">Mission</Link>
      </li>
      <li className="nav-item">
        <Link to="/">Team</Link>
      </li>
      <li className="nav-item">
        <Link to="/">Contact</Link>
      </li>
    </ul>
  </nav>
)

const PageNav = () => (
  <div>
    {/* Placeholder for pages */}
  </div>
)

export const Nav = ({ home }) => (
  <>
    {home ? <MainNav /> : <PageNav />}
  </>
)

Nav.propTypes = {
  home: PropTypes.bool
}

export default Nav
