import React from 'react'

import '../css/footer.css'

const Footer = () => {
  return (
    <footer className="footer">
      <span className="footer-highlight">&copy;</span>{' '}
      {new Date().getFullYear()} | Level Up in Tech
    </footer>
  )
}

export default Footer
