import PropTypes from 'prop-types'
import React from 'react'
import Logo from './logo'
import TitleText from './title-text'

import '../css/header.css'

const MainHeader = () => (
  <header className="header container">
    <Logo />
    <TitleText />
  </header>
)

const PageHeader = () => (
  <div>
    {/* This is where the secondary header components will go */}
  </div>
)

const Header = ({ home }) => (
  <>
    {home ? <MainHeader /> : <PageHeader />}
  </>
)

Header.propTypes = {
  home: PropTypes.bool
}

export default Header
